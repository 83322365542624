






























































































































































import { SerialPart, SerialPartType } from '@/models/form/WidgetPropertitesModel';
import { Component, Mixins, InjectReactive } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';
import { buildFormWidgetTree, getFormConfig } from '../../utils';

/**
 * input的配置项
 */
@Component({
  name: 'NumberConfig',
  components: {}
})
export default class extends Mixins(ElePropertiesMixins) {
  formWidgetTree: any = [];
  // 编号组成部分类型
  serialPartTypes: Record<string, string>[] = [];

  @InjectReactive()
  formId: any;

  // 缓存中的表单配置
  get _formConfig() {
    return getFormConfig(this.formId);
  }

  created(): void {
    this.getItemTypes();
    this.formWidgetTree = buildFormWidgetTree(this._formConfig.container.components);
  }

  getItemTypes(): void {
    for (var innertype in SerialPartType) {
      let itemType: Record<string, string> = {
        key: innertype,
        label: SerialPartType[innertype]
      };
      this.serialPartTypes.push(itemType);
    }
  }

  addCodePart(): void {
    let part: SerialPart = {
      type: ''
    };
    if (this.widget.properties.serialNumberSetting) {
      this.widget.properties.serialNumberSetting.serialParts.push(part);
    }
  }
  removeCodePart(index): void {
    if (this.widget.properties.serialNumberSetting) {
      this.widget.properties.serialNumberSetting.serialParts.splice(index, 1);
    }
  }

  /**
   * 表单树格式器
   * @param {number} param
   * @returns {number}
   */
  formNormalizer(node) {
    return {
      id: node.compCode,
      label: node.label,
      children: node.children
    };
  }
}
