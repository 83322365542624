var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.widget.compType === "inputNumber",
          expression: "widget.compType === 'inputNumber'",
        },
      ],
    },
    [
      [
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_name") } },
          [
            _c("lang-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compName,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compName", $$v)
                },
                expression: "widget.compName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_component_code") } },
          [
            _c("el-input", {
              staticClass: "input",
              attrs: {
                placeholder: _vm.$t("lang_please_enter"),
                clearable: "",
              },
              model: {
                value: _vm.widget.compCode,
                callback: function ($$v) {
                  _vm.$set(_vm.widget, "compCode", $$v)
                },
                expression: "widget.compCode",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_width") } },
          [
            _c("el-input-number", {
              attrs: { min: 0 },
              model: {
                value: _vm.widget.properties.labelWidth,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "labelWidth", $$v)
                },
                expression: "widget.properties.labelWidth",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_display_label") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.showLabel,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "showLabel", $$v)
                },
                expression: "widget.properties.showLabel",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_position") } },
          [
            _c(
              "el-select",
              {
                model: {
                  value: _vm.widget.properties.labelPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "labelPosition", $$v)
                  },
                  expression: "widget.properties.labelPosition",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_left"), value: "left" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_center"), value: "center" },
                }),
                _c("el-option", {
                  attrs: { label: _vm.$t("lang_align_right"), value: "right" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_label_border") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.cellBorder,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "cellBorder", $$v)
                  },
                  expression: "widget.properties.cellBorder",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v(_vm._s(_vm.$t("lang_display"))),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v(_vm._s(_vm.$t("lang_hide"))),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_minimum_value") } },
          [
            _c("el-input-number", {
              model: {
                value: _vm.widget.properties.min,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "min", $$v)
                },
                expression: "widget.properties.min",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_maximum") } },
          [
            _c("el-input-number", {
              model: {
                value: _vm.widget.properties.max,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "max", $$v)
                },
                expression: "widget.properties.max",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_step") } },
          [
            _c("el-input-number", {
              model: {
                value: _vm.widget.properties.step,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "step", $$v)
                },
                expression: "widget.properties.step",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_accuracy") } },
          [
            _c("el-input-number", {
              attrs: { min: 0, max: 10 },
              model: {
                value: _vm.widget.properties.precision,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "precision", $$v)
                },
                expression: "widget.properties.precision",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_button_position") } },
          [
            _c(
              "el-radio-group",
              {
                model: {
                  value: _vm.widget.properties.controlsPosition,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "controlsPosition", $$v)
                  },
                  expression: "widget.properties.controlsPosition",
                },
              },
              [
                _c("el-radio", { attrs: { label: "default" } }, [
                  _vm._v(_vm._s(_vm.$t("lang_default"))),
                ]),
                _c("el-radio", { attrs: { label: "right" } }, [_vm._v("右侧")]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_open_millimeter") } },
          [
            _c("el-switch", {
              model: {
                value: _vm.widget.properties.millimeterFlag,
                callback: function ($$v) {
                  _vm.$set(_vm.widget.properties, "millimeterFlag", $$v)
                },
                expression: "widget.properties.millimeterFlag",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_operation_status") } },
          [
            _c(
              "el-select",
              {
                attrs: {
                  placeholder: _vm.$t("lang_please_select"),
                  clearable: "",
                },
                model: {
                  value: _vm.widget.properties.operation,
                  callback: function ($$v) {
                    _vm.$set(_vm.widget.properties, "operation", $$v)
                  },
                  expression: "widget.properties.operation",
                },
              },
              _vm._l(_vm.statusList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.label, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "el-form-item",
          { attrs: { label: _vm.$t("lang_serial_number1") } },
          [
            _c("el-button", {
              attrs: {
                type: "primary",
                circle: "",
                size: "small",
                icon: "el-icon-plus",
              },
              on: { click: _vm.addCodePart },
            }),
            _vm.widget.properties.serialNumberSetting
              ? _c("el-input-number", {
                  attrs: { placeholder: "请选择最大长度", min: 0, max: 10 },
                  model: {
                    value: _vm.widget.properties.serialNumberSetting.length,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.widget.properties.serialNumberSetting,
                        "length",
                        $$v
                      )
                    },
                    expression: "widget.properties.serialNumberSetting.length",
                  },
                })
              : _vm._e(),
            _vm.widget.properties.serialNumberSetting
              ? _vm._l(
                  _vm.widget.properties.serialNumberSetting.serialParts,
                  function (part, index) {
                    return _c("div", { key: index, staticClass: "group" }, [
                      _c(
                        "div",
                        [
                          _c("el-button", {
                            attrs: {
                              type: "danger",
                              circle: "",
                              size: "small",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeCodePart(index)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "group-item" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: _vm.$t(
                                  "lang_please_select_component_type"
                                ),
                              },
                              model: {
                                value: part.type,
                                callback: function ($$v) {
                                  _vm.$set(part, "type", $$v)
                                },
                                expression: "part.type",
                              },
                            },
                            _vm._l(
                              _vm.serialPartTypes,
                              function (serialPartType, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: serialPartType.label,
                                    value: serialPartType.key,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      part.type == "FORM_MODEL"
                        ? _c(
                            "div",
                            { staticClass: "group-item" },
                            [
                              _c("treeselect", {
                                attrs: {
                                  multiple: false,
                                  value: "compCode",
                                  appendToBody: "",
                                  normalizer: _vm.formNormalizer,
                                  zIndex: "9999",
                                  "disable-branch-nodes": true,
                                  options: _vm.formWidgetTree,
                                  placeholder: _vm.$t("lang_please_select"),
                                },
                                model: {
                                  value: part.formModelCode,
                                  callback: function ($$v) {
                                    _vm.$set(part, "formModelCode", $$v)
                                  },
                                  expression: "part.formModelCode",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      part.type == "VARIABLE"
                        ? _c(
                            "div",
                            { staticClass: "group-item" },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择参数" },
                                  model: {
                                    value: part.varName,
                                    callback: function ($$v) {
                                      _vm.$set(part, "varName", $$v)
                                    },
                                    expression: "part.varName",
                                  },
                                },
                                _vm._l(
                                  _vm._formConfig.container.paramsOptions,
                                  function (formParam, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: formParam.text,
                                        value: formParam.name,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ])
                  }
                )
              : _vm._e(),
          ],
          2
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }